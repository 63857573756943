import React from 'react';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import Navbar from '../../components/Navbar';
import './index.css';

import CRMI from '../../images/CRM.png';
import CRMenu from '../../images/CRMenu.png';
import CRWorkflow from '../../images/CRWorkflow.png';
import CRWorkflowFull from '../../images/CRWorkflowFull.png';
import CRProcess from '../../images/CRProcess.png';
import ProcessOptions from '../../images/ProcessOptions.png';
import EdicaoProcesso from '../../images/EdicaoProcesso.png';
import EPFinanceiro from '../../images/EPFinanceiro.png';
import TaskProcess from '../../images/TaskProcess.png';
import DocProcess from '../../images/DocProcess.png';
import EmailProcess from '../../images/EmailProcess.png';
import ObserProcess from '../../images/ObserProcess.png';
import CamposProcess from '../../images/CamposProcess.png';
import HistProcess from '../../images/HistProcess.png';
import SMSProcess from '../../images/SMSProcess.png';
import TemplateSMS from '../../images/TemplateSMS.png';
import PacoteSMS from '../../images/PacoteSMS.png';

import EmailCRM from '../../images/EmailCRM.png';
import EmailOptions from '../../images/EmailOptions.png';
import NewEmail from '../../images/NewEmail.png';
import EmailOp from '../../images/EmailOp.png';
import EditEmail from '../../images/EditEmail.png';
import EditEmailOp from '../../images/EditEmailOp.png';
import BodyEmail from '../../images/BodyEmail.png';
import BodyOptions from '../../images/BodyOptions.png';
import BodyOp from '../../images/BodyOp.png';
import Pincel from '../../images/Pincel.png';
import BodySettings from '../../images/BodySettings.png';
import Camadas from '../../images/Camadas.png';
import Tools from '../../images/Tools.png';
import ToolsSec from '../../images/ToolsSec.png';

import PAIntemporal from '../../images/PAIntemporal.png';
import PATemporaal from '../../images/PATemporaal.png';
import PAOptions from '../../images/PAOptions.png';
import NewPAT from '../../images/NewPAT.png';
import ClickPAT from '../../images/ClickPAT.png';
import StepsPAI from '../../images/StepsPAI.png';
import StepsOne from '../../images/StepsOne.png';
import NewStep from '../../images/NewStep.png';
import PATL from '../../images/PATL.png';
import PATOptions from '../../images/PATOptions.png';
import PATOp from '../../images/PATOp.png';
import StepPAT from '../../images/StepPAT.png';
import OpPAT from '../../images/OpPAT.png';
import ReportPAT from '../../images/ReportPAT.png';
import ReportPATOp from '../../images/ReportPATOp.png';
import AllReport from '../../images/AllReport.png';
import Export from '../../images/Export.png';
import Relatorio from '../../images/Relatorio.png';



function CRM() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              CRM                                                          */}

      <h1 id='tituloCR'> | CRM</h1>
      <h3 id='segundoCR'> Este módulo tem como objetivo o registo de toda a atividade relacionada com o processo de comunicação da base de dados inserida no portal. </h3>
      <img src={CRMI} alt='CRMI' id='imageCROne' />

      <h3 id='segundoCR'> O módulo CRM tem várias funcionalidades na plataforma, de modo a tornar o seu trabalho cada vez mais eficaz e organizado! </h3>
      <h3 id='segundoCR'> Crie Planos de Ação (Campanhas) para os seus clientes, envie os mesmos com disparos automáticos,
        através SMS e Emails pré-configurados e preencha os relatórios. </h3>
      <img src={CRMenu} alt='CRMenu' id='imageCOne' />

      {/*                                             WORKFLOW                                                       */}

      <h1 id='tituloCRSegundo'> || WORKFLOW</h1>
      <h3 id='segundoCR'> O Workflow do módulo CRM permite-lhe visualizar todos os processos existentes.
        Pode visualizar os ativos, os finalizados, os arquivados e os menus.
        Dispõe também de ações de edição e remoção dos seus workflows. </h3>
      <img src={CRWorkflow} alt='CRWorkflow' id='imageCTwo' />
      <h3 id='segundoCR'> Clicando no nome do seu Workflow, poderá personalizá-lo de modo a ser dinâmico para si e para os seus colaboradores. </h3>
      <img src={CRWorkflowFull} alt='CRWorkflowFull' id='imageCTwo' />

      <h3 id='segundoCR'> O Workflow possui opções como adicionar um Novo Menu, um novo processo,
        definir anexos onde poderá selecionar um grupo de documentos previamente definidos, definir campos personalizados previamente definidos e
        possibilidade de adicionar arquivos ao Workflow. </h3>

      <h3 id='segundoCR'> Ao adicionar um novo processo, irá abrir um formulário de preenchimento de processo. Nele, poderá definir
        a quem se destina esse processo, a qual menu deseja atribuir esse processo, o seu status, a data limite e qual a prioridade.
        Poderá notificar também o utilizador ou subcontacto que se destina este processo. </h3>
      <img src={CRProcess} alt='CRProcess' id='imageCThree' />

      <h3 id='segundoCR'> Clicando no nome do seu Workflow, poderá personalizá-lo de modo a ser dinâmico para si e para os seus colaboradores.
        Os processos após a sua criação, poderá visualizá-los no menu que o atribuiu. Ele dispõe de 4 opções: A versão expandida para visualizar mais detalhes,
        A ficha do Cliente para o qual se destina este processo e o qual definiu durante a sua criação, poderá adicionar algumas observações relativamente a este processo,
        editar o processo ou arquivá-lo quando concluido. </h3>
      <img src={ProcessOptions} alt='ProcessOptions' id='imageCThre' />

      {/*                                             EDIÇÃO DE WORKFLOW PROCESS                                                       */}

      <h1 id='tituloCRSegundo'> ||| EDIÇÃO DE WORKFLOW PROCESS</h1>
      <h3 id='segundoCR'> Ao editar o seu processo, poderá adicionar mais algumas informações que não estavam disponiveis no formulário de criação de processo. </h3>
      <img src={EdicaoProcesso} alt='EdicaoProcesso' id='imageCFour' />

      <h3 id='segundoCR'> Ao selecionar a opção de edição, poderá editar os dados que inseriu no formulário de criação de processo.
        Poderá visualizar a entidade e o subcontacto e as suas respetivas informações. A opção Financeiro permite á entidade definir valores respetivos ao seu projeto, custos,
        a data limite e a probabilidade de conclusão do negócio.</h3>
      <img src={EPFinanceiro} alt='EPFinanceiro' id='imageCFour' />

      <h3 id='segundoCR'> Poderá adicionar também: Tarefas aos processos. </h3>
      <img src={TaskProcess} alt='TaskProcess' id='imageCFour' />
      <h3 id='segundoCR'> Adicionar ficheiros.</h3>
      <img src={DocProcess} alt='DocProcess' id='imageCFour' />
      <h3 id='segundoCR'> Escrever um email para a entidade a quem se destina este processo
        da mesma maneira como seria escrever um email a partir do perfil individual da entidade, anteriormente referido. Em seguida, poderá ver o seu histórico de emails.</h3>
      <img src={EmailProcess} alt='EmailProcess' id='imageCFour' />
      <h3 id='segundoCR'> Poderá adicionar pequenas observações. </h3>
      <img src={ObserProcess} alt='ObserProcess' id='imageCFour' />
      <h3 id='segundoCR'> Visualizar os campos personalizados. </h3>
      <img src={CamposProcess} alt='CamposProcess' id='imageCFour' />
      <h3 id='segundoCR'> Visualizar o histórico das modificações que fez neste processo. </h3>
      <img src={HistProcess} alt='CamposProcess' id='imageCFour' />

      {/*                                                  SMS                                                       */}

      <h1 id='tituloCRSegundo'> || SMS</h1>
      <h3 id='segundoCR'> A plataforma disponibiliza a possibilidade de envio de SMSs automatizados.
        Com a definição da data e hora, assim como a personalização de cada mensagem.O utilizador não tem que se preocupar mais com o disparo de mensagens.
        Configure e envie para a sua base de dados, mensagens de texto personalizadas.
        Desde desejar “feliz aniversário”, ou relembrar o cliente de uma visita ou escritura.</h3>

      <h3 id='segundoCR'> A página de configuração de SMS permite personalizar os vários tipos de mensagens que poderão ser enviados para as entidades/clientes.
        Esta configuração poderá depois ser utilizada na página de cada entidade aquando da configuração de um novo envio de sms para essa entidade.
        Ajuste o seu pacote de SMS conforme as suas necessidades, solicitando um pacote SMS.
      </h3>
      <img src={SMSProcess} alt='SMSProcess' id='imageCTwo' />

      <h1 id='tituloCRSegundo'> ||| TEMPLATE DE SMS</h1>
      <h3 id='segundoCR'> Existem duas formas de utilizar os SMS: A primeira é pela criação de templates para a sua associação a um novo plano de ação,
        de modo a enviar esse padrão de mensagem aos clientes:  </h3>
      <img src={TemplateSMS} alt='TemplateSMS' id='imageCThree' />
      <h3 id='segundoCR'> E a segunda é a criação de uma mensagem direta, anteriormente referida, no perfil completo de um usuário.  </h3>

      <h1 id='tituloCRSegundo'> ||| SOLICITAR PACOTE DE SMS</h1>
      <h3 id='segundoCR'> Caso já não tenha mais SMS para enviar, poderá sempre solicitar mais! Selecione a opção que deseja e será redirecionado para a página de pagamento.
        Após concluido e confirmado o pagamento, já poderá enviar mais SMSs para as suas entidades. </h3>
      <img src={PacoteSMS} alt='PacoteSMS' id='imageCThree' />

      {/*                                                  EMAIL                                                       */}

      <h1 id='tituloCRSegundo'> || EMAIL</h1>
      <h3 id='segundoCR'> É possível o envio de emails automatizados através da plataforma. Configure e envie para a sua base de dados, mensagens de texto personalizadas.
        Quer saber como vai ficar o seu email antes de ser enviado? Não se preocupe que poderá fazer um envio teste antes de enviar o mesmo para os seus clientes. </h3>
      <img src={EmailCRM} alt='EmailCRM' id='imageCTwo' />

      <h3 id='segundoCR'> A plataforma disponibiliza as seguintes opções: Editar nome Template (Para saber qual o assunto tratado no email),
        Definir layout (Como será estruturado o seu email), Duplicar template (Caso deseje ter mais que um), Anexar Ficheiros (Ficheiros a serem enviados juntamente com o email),
        Testar o email (Testar se o email chega ao seu destino), Remover template (Eliminar). </h3>
      <img src={EmailOptions} alt='EmailOptions' id='imageCFive' />

      <h3 id='segundoCR'> Da mesma forma que existem duas maneiras de trabalhar com os SMS, a plataforma permite o mesmo para os emails, no entanto,
        a criação de templates para os emails são feitos de maneira diferente.
        Ao clicar em “Novo Template Email”, o seu template ficará criado. No entanto, terá de criar um layout visual para o seu email. </h3>
      <img src={NewEmail} alt='NewEmail' id='imageCFive' />

      <h3 id='segundoCR'> Ao ficar criado, clique na segunda ação do seu email. </h3>
      <img src={EmailOp} alt='EmailOp' id='imageCFive' />

      <h3 id='segundoCR'> Ao clicar, será aberta uma nova janela. Nessa janela, irá começar a desenhar o layout do seu email a seu gosto. </h3>
      <img src={EditEmail} alt='EditEmail' id='imageCTwo' />

      <h3 id='segundoCR'> As opções do canto superior direito, possibilitam ao usuário visualizar o seu layout em ecrã de computador, em ecrã de tablet e em ecrã de mobile.
        É bastante útil para o criador do layout ter uma noção mais real de como ficará o seu email visto em diferentes tipos de ecrã. </h3>
      <img src={EditEmailOp} alt='EditEmailOp' id='imageCFive' />

      <h3 id='segundoCR'> No centro, apresenta-se um pequeno layout vazio, ao qual o usuário terá de personalizar a seu gosto.
        O head seria a parte de cima do seu email onde ficaria o logótipo, nome, etc.
        O body seria o corpo da mensagem, onde iria assentar a informação do email.
        O footer seria a parte de baixo do email, onde pode conter algumas informações de contacto da sua empresa, etc.
      </h3>
      <img src={BodyEmail} alt='BodyEmail' id='imageCThree' />

      <h3 id='segundoCR'> No canto superior esquerdo, temos algumas opções relativas ao layout. Poderá visualizar os componentes com as linhas ou sem as linhas,
        poderá colocar a janela em tela cheia, visualizar e editar o código caso se sinta mais confortável para o fazer, fazer download do layout,
        retroceder e retornar algumas modificações feitas, guardar ou fechar a janela. </h3>
      <img src={BodyOptions} alt='BodyOptions' id='imageCFive' />

      <h3 id='segundoCR'> As opções de personalização presentes no canto superior direito, permitem ao usuário abrir o gerenciador de estilos,
        alterar as definições, abrir o gerenciador de camadas do layout e abrir os elementos que estarão presentes no visual do seu layout. </h3>
      <img src={BodyOp} alt='BodyOp' id='imageCFive' />

      <h3 id='segundoCR'> Ao clicar num elemento do Body, serão-lhe apresentadas algumas opções no gerenciador de estilos, como:  </h3>
      <img src={Pincel} alt='Pincel' id='imageCSix' />

      <h3 id='segundoCR'> Aqui poderá alterar algumas especificações visuais do seu email, tipos de letra, as dimensões dos componentes, a cor de fundo, etc.
        Nas definições, altere todos os IDs e o título para algo relacionado ao que o elemento é. Ou seja, se for uma imagem, poderá colocar como ID e título: “img”.
        Ambos poderão ter o mesmo nome, pois as suas funções são diferentes. O ID será bastante utilizado em termos de código para estilização, caso utilize essa opção.
        O título serve para identificar de uma maneira mais ágil qual o componente.
      </h3>
      <img src={BodySettings} alt='BodySettings' id='imageCSix' />

      <h3 id='segundoCR'> No gerenciador de camadas, poderá visualizar os componentes que compõem o seu email. Poderá alterar a sua posição, poderá ocultá-los caso deseje. </h3>
      <img src={Camadas} alt='Camadas' id='imageCSix' />

      <h3 id='segundoCR'> Existem alguns elementos que poderá predefinir para clientes específicos. A plataforma disponibiliza
        algumas ferramentas para a criação de emails personalizados e específicos para clientes.  </h3>
      <img src={Tools} alt='Tools' id='imageCSix' />

      <h3 id='segundoCR'> Todos estes elementos, poderá personalizar para um cliente. Os elementos estão relacionados com o tipo de Saudação, Nome, Email e
        Apelido do perfil de um cliente.
        Os restantes elementos servirão para compor melhor o visual do seu email. Após a sua conclusão, não se esqueça de guardar o layout.
      </h3>
      <img src={ToolsSec} alt='ToolsSec' id='imageCSix' />

      {/*                                                  PLANOS DE AÇÃO                                                       */}
      {/*                                                    INTEMPORAL                                                         */}


      <h1 id='tituloCRSegundo'> || PLANOS DE AÇÃO</h1>
      <h3 id='segundoCR'> Crie planos de ação para os seus clientes. Com os planos de ação permite disparar campanhas com temas relevantes.
        Envie emails com newsletters ou então informe os seus clientes via SMS com alguma promoção ou acontecimento que queira divulgar.
        Os planos de ação podem ser: Temporal (com data definida) ou Intemporal (sem data) mas definimos quando este plano começa a decorrer.</h3>
      <img src={PAIntemporal} alt='PAIntemporal' id='imageCTwo' />
      <img src={PATemporaal} alt='PATemporaal' id='imageCTwo' />

      <h3 id='segundoCR'> Poderá copiar, exportar os seus planos em Excel ou PDF ou até mesmo imprimir para fazer os seus backups. </h3>
      <img src={PAOptions} alt='PAOptions' id='imageCFive' />

      <h1 id='tituloCRSegundo'> ||| CRIAÇÃO DE UM PLANO DE AÇÃO INTEMPORAL</h1>
      <h3 id='segundoCR'> A criação de um plano de ação é bastante simples. Basta clicar no botão de Adicionar um plano de ação, neste caso, intemporal.
        Poderá ser temporal também, mas para este exemplo, utilizaremos o intemporal. </h3>
      <img src={NewPAT} alt='NewPAT' id='imageCEight' />

      <h3 id='segundoCR'> Ao clicar, será aberta uma nova janela para definirmos o nome do nosso plano. O nome deste plano será “Teste Laura”.
        Após o Plano ser criado, vamos clicar no nome para adicionarmos Passos. </h3>
      <img src={ClickPAT} alt='ClickPAT' id='imageCNine' />

      <h3 id='segundoCR'> Após o clique, será redirecionado a uma nova aba de informações. Nela contém quantos passos existem no seu plano.  </h3>
      <img src={StepsPAI} alt='StepsPAI' id='imageCTwo' />

      <h3 id='segundoCR'> Neste plano ainda não existem passos mas, para realmente o plano funcionar, necessitaremos de no mínimo 1 passo.
        Cliquemos na opção “Adicionar Passo”. </h3>
      <img src={StepsOne} alt='StepsOne' id='imageCThree' />

      <h3 id='segundoCR'> Ao clicarmos, será aberto um pequeno formulário de preenchimento. Será solicitado que defina qual o tipo de ação: Enviar SMS, Enviar Email ou Interação.
        Ao clicar em Enviar SMS ou Enviar Email, será solicitado que escolha um template que anteriormente criou.
        A interação será uma tarefa que poderá atribuir a alguém e definir um horário para a sua execução.
        Poderá colocar uma breve descrição, quantos dias após o passo anterior e a hora que este passo será executado.
        Caso seja o primeiro passo, poderá deixar os dias a 0 e definir os próximos passos a partir daí.
        Caso queira que o próximo passo seja daqui a 15 dias, só colocar 15 dias no próximo passo.  </h3>
      <img src={NewStep} alt='NewStep' id='imageCThree' />

      {/*                                                    TEMPORAL                                                           */}

      <h1 id='tituloCRSegundo'> ||| CRIAÇÃO DE UM PLANO DE AÇÃO TEMPORAL</h1>
      <h3 id='segundoCR'> Os planos de ação temporais funcionam de maneira diferente. Poderá definir passos para
        a execução de tarefas com uma data específica para serem concluídas.
        A criação de um plano temporal funciona da mesma forma que o intemporal.
        Após a criação de um Plano de Ação Temporal, cliquemos no nome do mesmo para visualizar os nossos passos.
        Note que se o seu plano for novo e não tiver nenhum passo, não aparecerá nada.
      </h3>
      <img src={PATL} alt='PATL' id='imageCTwo' />

      <h3 id='segundoCR'> Adicionemos um passo novo no Plano de Ação Temporal. A sua criação será feita de acordo com o tipo de ação pretendida.
        Note a diferença entre ambos na criação de um passo. </h3>
      <img src={PATOptions} alt='PATOptions' id='imageCTen' />

      <h3 id='segundoCR'> Ao iniciarmos a criação de um novo passo temporal, escolha uma ação.
        Ao escolher cada uma das ações, o formulário de preenchimento do passo será bastante semelhante ao plano intemporal.
        O que difere entre ambos é o tempo de duração do passo. O Plano de Ação Intemporal, solicita-lhe apenas uma hora de execução.
        Poderá enviar uma mensagem aquela X hora do próprio dia e após essa hora, o passo dá-se como concluído.
        Poderá ser utilizado como um lembrete único, tanto para si como para o cliente.
        Poderá utilizar para lembrar de uma reunião existente.
        O Plano de Ação Temporal é um plano mais rígido que exige ao usuário que defina uma data e hora da sua conclusão.
        Poderá utilizar juntamente com um projeto ou eventos como o natal, etc.
      </h3>
      <img src={PATOp} alt='PATOp' id='imageCEleven' />

      <h3 id='segundoCR'> Após a confirmação, poderá visualizar o passo criado. </h3>
      <img src={StepPAT} alt='StepPAT' id='imageCThree' />

      <h3 id='segundoCR'> Para enviar para os clientes ou colaboradores, associe este plano de ação a uma lista que contenha os contactos a quem se vai destinar estas ações.
        Após a conclusão do primeiro passo, poderá clicar na Ação de Verificar Status. </h3>
      <img src={OpPAT} alt='OpPAT' id='imageCTwo' />

      <h3 id='segundoCR'> Será aberto um pequeno histórico dos passos já executados por quantas pessoas e a que horas. </h3>
      <img src={ReportPAT} alt='ReportPAT' id='imageCSeven' />

      <h3 id='segundoCR'> Cada passo concluído permite-lhe ainda aprofundar mais os seus dados, para que seja possível fazer relatórios cada vez mais completos.
        Clicando na opção de gráfico, poderá visualizar um pequeno gráfico com a percentagem dos passos que foram entregues, os que não foram, etc.
      </h3>
      <img src={ReportPATOp} alt='ReportPATOp' id='imageCSeven' />

      <h3 id='segundoCR'> A opção do lado direito, o “olho”, permite-lhe ver com mais detalhes para quem foi enviado, os emails, o estado e a data e hora.
        A plataforma ainda permite-lhe copiar, exportar os dados ou imprimir.
      </h3>
      <img src={AllReport} alt='AllReport' id='imageCThree' />
      <img src={Export} alt='Export' id='imageCSeven' />

      {/*                                                    RELATÓRIOS                                                           */}

      <h1 id='tituloCRSegundo'> || Relatórios</h1>
      <h3 id='segundoCR'> Pode através dos dados presentes no portal gerar relatórios.
        Esta aba demonstra vários gráficos, de modo, a ter um controle de tudo o que é feito.
        Nomeadamente, os contactos e os leads, a quantidade de tarefas que estão em dia, futuras e ter um maior controlo das atrasadas de modo a diminuir esse volume.
        A quantidade de emails e SMS enviados e os que ainda estão disponíveis. O Workflow do CRM está disponível para uma visualização dos processos
        finalizados e os que ainda estão ativos.
      </h3>
      <img src={Relatorio} alt='Relatorio' id='imageCTwo' />


    </>
  )
}

export default CRM;