import React from 'react';
import NavbarInicial from '../../components/NavbarInicial';


function PagInicial() {
  return (
    <div>
      <NavbarInicial />
    </div>
  )
}

export default PagInicial;