import React from 'react';


import Navbar from '../../components/Navbar';
import Tec from '../../images/Tec.png';
import Atuali from '../../images/Atuali.png';
import '../suporteTecnico/index.css';


function STecnico() {
  return (
    <>

      <Navbar />

      {/*                                                   SUPORTE TÉCNICO                                                            */}

      <h1 id='titulotec'> | Suporte Técnico</h1>
      <h3 id='primeirotec'> Em caso de necessidade de assistência, poderá sempre preencher o formulário e enviar com um pedido de suporte, descrevendo em que gostaria de ser ajudado e para onde gostaria de ser contactado. </h3>
      <img src={Tec} alt="Form de STecnico" id='imagestec' />
      <h3 id='primeirotec'> Em outro caso, poderá ligar para o número de telefone ou enviar um email para os contactos abaixo indicados na linha azul.
      </h3>

      {/*                                                     ATUALIZAÇÕES                                                             */}

      <h1 id='titulotec'> | Atualizações</h1>
      <h3 id='primeirotec'> Poderá consultar as atualizações feitas à plataforma de forma descritiva na aba "Atualizações" no menu lateral.
        Será descrita a versão, o dia de lançamento e as modificações feitas à plataforma. </h3>
      <img src={Atuali} alt="Atualizações" id='imagesatuali' />


    </>
  )
}

export default STecnico;