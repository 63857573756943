import React from 'react';
import '../eventos/index.css';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import Navbar from '../../components/Navbar';

import Eventos from '../../images/Events.png';
import NewEvent from '../../images/NewEvent.png';
import Form from '../../images/Form.png';
import Forms from '../../images/Forms.png';


function Events() {
    return (
        <>

            <Navbar />
            <ButtonScrollUp />

            {/*                                              EVENTOS                                                             */}

            <h1 id='tituloEvent'> | EVENTOS</h1>
            <h3 id='segundoEvent'> O Spot4All disponibiliza uma função de "Eventos". Os Eventos têm como propósito uma maior agilidade na recolha de informações durante
                a realização de um evento. Recolha informações de potenciais clientes ou de clientes já existentes e guarde as suas informações de contacto para contactos futuros!</h3>
            <img src={Eventos} alt='Events' id='imagesEvent' />

            {/*                                        CRIAÇÃO DE UM EVENTO                                                      */}

            <h1 id='titulodashsegundo'> || CRIAÇÃO DE UM EVENTO</h1>
            <h3 id='segundoEvent'> A criação de um evento é bastante simples. Clicando no botão de "Novo Evento", aparecerá um formulário para preenchimento
                de informações sobre o evento. Poderá preencher com as informações gerais, qual o local, o nome, em que dia ocorrerá, quais as pessoas que estarão envolvidas, etc.</h3>
            <img src={NewEvent} alt='NewEvent' id='imagesEventOne' />

            {/*                                             FORMULÁRIO                                                           */}

            <h1 id='titulodashsegundo'> || FORMULÁRIO</h1>
            <h3 id='segundoEvent'> Após a criação de um evento, terá á sua disposição um formulário para os seus convidados preencherem e assim guardar as suas
                informações de contacto caso desejem ser contactados posteriormente para uma futura parceria de negócio! </h3>
            <img src={Form} alt='Form' id='imagesEvent' />

            <h3 id='segundoEvent'> Poderá pré-visualizar o formulário de evento ao clicar na URL do Formulário. Para o seu evento, somente o formulário estará disponivel para preenchimento e envio.
                Poderá verificar quantos formulários foram enviados. </h3>
            <h3 id='segundoEvent'> Poderá Editar as informações do seu evento ou até mesmo proceder á sua eliminação!</h3>
            <img src={Forms} alt='Forms' id='imagesEventTwo' />





        </>
    )
}

export default Events;