import React from 'react';
import Navbar from '../../components/Navbar';
import '../fichaEmpresa/index.css'
import fichaEmpresa from '../../images/ficha.png'

function FEmpresa() {
  return (
    <>

      <Navbar />

      <h1 id='tituloFicha'> | FICHA DA EMPRESA</h1>
      <h3 id='segundoFicha'> Tem a possibilidade de visualizar um breve resumo da sua empresa, nomeadamente quem é o seu responsável e os contactos previamente preenchidos.
        Uma timeline, tarefas existentes e utilizadores, e também, a possibilidade de visualizar as atividades em curso e a criação de um novo processo. </h3>
      <img src={fichaEmpresa} alt='fichaEmpresa' id='imagesFicha' />

    </>
  )
}

export default FEmpresa;