import React from 'react';

import Navbar from '../../components/Navbar';
import './index.css';

import Login from '../../images/Login.png';
import Perfil from '../../images/Perfil.png';
import PerfilDois from '../../images/PerfilDois.png';
import InfosAdic from '../../images/InfosAdic.png';
import Empresa from '../../images/Empresa.png';

import Dash from '../../images/Dash.png';
import Gestor from '../../images/Gestor.png';
import Tasks from '../../images/Tasks.png';
import Process from '../../images/Process.png';
import NewTask from '../../images/NewTask.png';
import Gerar from '../../images/Gerar.png';

import Nav from '../../images/Nav.png';
import NewContactOrLead from '../../images/NewContactOrLead.png';
import Contact from '../../images/Contact.png';
import ContactOne from '../../images/ContactOne.png';
import ContactTwo from '../../images/ContactTwo.png';

import GlobalSearch from '../../images/GlobalSearch.png';
import LocalSearch from '../../images/LocalSearch.png';
import ButtonScrollUp from '../../components/ButtonScrollUp';


function Dashboard() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              PARTE DO LOGIN                                                             */}
      <h1 id='titulodash'> | LOGIN</h1>
      <h3 id='segundodash'> No ecrã inicial introduzimos o email e a password para acesso ao portal,
        previamente recebidos por email assim que o administrador da plataforma adiciona um novo utilizador. </h3>
      <img src={Login} alt='Login' id='imagesdashoito' />

      {/*                                         PÁGINA DO PERFIL DA EMPRESA                                                     */}

      <h1 id='titulodashsegundo'> || PÁGINA DO PERFIL DA EMPRESA E DO UTILIZADOR</h1>
      <h3 id='primeirodash'> Clicando no seu perfil, abrirá um pequeno menu.
        Ao abrir, escolha entre “Minha Empresa” ou “Meu perfil”.
        Assim, consegue personalizar o perfil da sua empresa e o seu perfil.
        Faça o preenchimento dos campos de forma intuitiva. </h3>
      <h3 id='primeirodash'> A página da empresa apenas é acedida pelos administradores do sistema e
        detalha os dados da empresa assim como as pessoas responsáveis.
        De salientar que uma empresa pode ter várias moradas e várias pessoas responsáveis.</h3>
      <h3 id='primeirodash'> Ao aceder à aba de "Minha empresa", poderá alterar os dados da sua empresa e registar quem é o responsável pela mesma.
        Existem as opções de email, onde poderá testar a ligação e os SMS,
        onde poderá visualizar e gerir o que ainda tem disponível e quais os seus gastos.</h3>
      <img src={Empresa} alt='Minha Empresa' id='imagesdashnove' />

      {/*                                         PÁGINA DO PERFIL DO USUÁRIO                                                     */}

      <h3 id='primeirodash'> No nosso perfil de usuário, é possivel adicionar ou modificar algumas informações pessoais.
        É possivel também adicionar ou trocar a imagem de perfil que deseja que os restantes usuários visualizem.
        Poderá definir um identificador e um pin de um máximo de 6 digitos para cada entidade. Essa configuração
        servirá para o login do Ponto Web. </h3>
      <h3 id='primeirodash'> Com ele, poderá verificar com mais detalhes quantos, quais e em qual projeto os colaboradores estão a trabalhar.
        Terá a hipótese também de verificar quantas horas foram trabalhadas naquele projeto por um determinado colaborador.
        Por fim, poderá configurar também quanto o colaborador ganha á hora. Esse cálculo aparecerá também no Ponto Web como uma forma de 
        controlo de salário ao fim do mês de acordo com as horas trabalhadas no total, independentemente do projeto. </h3>
      <img src={Perfil} alt='Meu Perfil' id='imagesdashnove' />

      <h3 id='primeirodash'> Nos dados do Utilizador é possível, definir os envios de email e definir as preferências de visualização de tarefas e de processos. </h3>
      <img src={PerfilDois} alt='Meu Perfil' id='imagesdashdez' />

      <h3 id='primeirodash'> Na mesma página, existe ainda um pequeno menu de “Informações Adicionais”, onde poderá alterar a sua password,
        testar a ligação do seu email através da opção “Configurar email”,
        verificar o seu email através da opção “Configurar email - Premium”. </h3>
      <h3 id='primeirodash'> Poderá configurar a sua assinatura email,
        ao qual abrirá uma nova janela que possibilita a criação e estilização de uma assinatura.</h3>
      <img src={InfosAdic} alt='Infos Adicionais' id='imagesdashonze' />

      {/*                                                 DASHBOARD                                                               */}

      <h1 id='titulodash'> | DASHBOARD</h1>
      <h3 id='segundodash'> Ao fazer login visualizamos a página inicial da plataforma (Dashboard). Neste Dashboard consegue ter noção das tarefas dos seus gestores, enquanto Admin.
        Enquanto gestor tem uma visão das suas próprias tarefas/processos. O seu nome aparecerá do lado esquerdo da opção "Filtrar". Tem também um calendário para auxílio do seu trabalho: </h3>
      <img src={Dash} alt='dashboard' id='imagesdash' />

      <h3 id='segundodash'> No Dashboard temos um dropdown que apresenta os gestores que fazem parte da empresa. Só o administrador consegue ter esta visão de todos os gestores. <br />
        Temos um botão de adicionar uma tarefa para o gestor ou para todos. <br />
        O Admin pode selecionar um gestor e filtrar as tarefas (escrita livre) atribuídas a cada, para visualização das mesmas. </h3>
      <img src={Gestor} alt='Gestor' id='imagesdashdois' />

      {/*                                        BARRA DE CONTABILIZAÇÃO DE TAREFAS                                                */}

      <h1 id='titulodashsegundo'> || BARRA DE CONTABILIZAÇÃO DE TAREFAS</h1>
      <h3 id='primeirodash'> Às tarefas estão atribuídas cores: </h3>
      <ul id='texto'>
        <li><span id='primeirospan'> Vermelho | EM ATRASO </span>- Se a tarefa já passou o timing de resolução</li>
        <li><span id='segundospan'> Amarelo | DIA </span>- Se a tarefa está para ser resolvida no dia</li>
        <li><span id='terceirospan'> Azul | FUTURA </span>- Se está para ser resolvido futuramente</li>
      </ul>
      <img src={Tasks} alt='Tasks' id='imagesdashtres' />

      {/*                                              TAREFAS OU PROCESSOS                                                        */}

      <h1 id='titulodashsegundo'> || TAREFAS OU PROCESSOS</h1>
      <h3 id='primeirodash'> Poderá adicionar tarefas ou novos processos para todos ou para um usuário específico no Dashboard. </h3>
      <img src={Process} alt='Process' id='imagesdashquatro' />
      <img src={NewTask} alt='NewTask' id='imagesdashcinco' />
      <h3 id='primeirodash'> Poderá sempre clicar em cima das tarefas para visualizar o seu progresso. </h3>
      <h3 id='terceirodash'> Nota: Deverá ter existente um Sub Contacto para conseguir criar uma tarefa. </h3>

      {/*                                              GERAR PDF E CALENDÁRIO                                                      */}

      <h1 id='titulodashsegundo'> || GERAR PDF E CALENDÁRIO</h1>
      <h3 id='primeirodash'> É possível gerar um PDF com as tarefas do próprio utilizador. <br />
        É possível também, clicar no botão em formato de grelha para voltar a visualizar o seu calendário. </h3>
      <img src={Gerar} alt='Gerar' id='imagesdashseis' />

      {/*                                                    NAVEGAÇÃO                                                             */}

      <h1 id='titulodash'> | NAVEGAÇÃO</h1>
      <h3 id='segundodash'> A barra de navegação do Dashboard permite-lhe fazer várias coisas. <br />
        Identificar em qual categoria está, adicionar um contacto ou lead e pesquisar globalmente ou localmente na base de dados. </h3>
      <img src={Nav} alt='Gerar' id='imagesdashsete' />

      {/*                                             INSERIR NOVO CONTACTO OU LEAD                                                */}

      <h1 id='titulodashsegundo'> || INSERIR NOVO CONTACTO OU LEAD</h1>
      <h3 id='segundodash'> Em toda a aplicação estão disponíveis os botões para registo de um novo
        contacto ou uma nova lead, (parte superior direita), sendo também possível fazer uma pesquisa dos mesmos: </h3>
      <img src={NewContactOrLead} alt='NewContactOrLead' id='imagesdashdoze' />

      <h3 id='segundodash'> Após o clique para novo contacto ou lead, abre a janela para preencher com os dados do mesmo: </h3>
      <img src={Contact} alt='Novo Contacto' id='imagesdashquinze' />
      <img src={ContactOne} alt='Novo Contacto' id='imagesdashtreze' />
      <img src={ContactTwo} alt='Novo Contacto' id='imagesdashcatorze' />
      <h3 id='segundodash'><span id='colorRed'>Para inserção de um novo Contacto ou Lead,
        é obrigatório o preenchimento dos campos: Nome + Email + Classificação. </span></h3>
      <h3 id='segundodash'> Existe a possibilidade de um Contacto ou Lead ser criado
        com o nome do próprio utilizador, ou poderá ser criado em nome do utilizador que quiser.</h3>

      {/*                                                     PESQUISA                                                            */}

      <h1 id='titulodashsegundo'> || PESQUISA</h1>
      <h3 id='segundodash'> Pesquise de forma livre e global o pretendido em todas as bases de dados a que estiver associado. </h3>
      <img src={GlobalSearch} alt='GlobalSearch' id='imagesdashdoze' />

      <h3 id='segundodash'> Se tiver optado por clicar em cima do ícone apenas vai pesquisar na sua base de dados. </h3>
      <img src={LocalSearch} alt='LocalSearch' id='imagesdashdoze' />

      <ButtonScrollUp />
    </>
  )
}

export default Dashboard;