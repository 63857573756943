import React from 'react';
import '../ButtonScrollUp/index.css';


function ButtonScrollUp() {

    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        const button = document.getElementById("myBtn");

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            button.style.display = "block";
        } else {
            button.style.display = "none";
        }
    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
            <button onClick={topFunction} id="myBtn">
                <i className="material-icons">keyboard_arrow_up</i>
            </button>
        </>
    )
}

export default ButtonScrollUp;