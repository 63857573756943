import React from 'react';
import Navbar from '../../../components/Navbar';
import ButtonScrollUp from '../../../components/ButtonScrollUp';
import './index.css';

import GE from '../../../images/GE.png';
import US from '../../../images/US.png';
import USActions from '../../../images/USActions.png';
import USC from '../../../images/USC.png';
import Permissoes from '../../../images/Permissoes.png';

function GestaoEquipa() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              GESTAO-EQUIPA                                                          */}

      <h1 id='tituloGE'> | GESTÃO DE EQUIPA</h1>
      <h3 id='segundoGE'> Este módulo tem como objetivo auxiliar a entidade a gerir a sua equipa de modo eficiente e organizado. Poderá gerir os usuários na sua base de dados,
        ver quem realizou modificações, e ver de uma forma geral, os subcontactos existentes na sua base de dados. </h3>
      <img src={GE} alt='GE' id='imageGEOne' />

      {/*                                              LISTA DE UTILIZADORES                                                          */}

      <h1 id='tituloGESegundo'> || LISTA DE UTILIZADORES</h1>
      <h3 id='segundoGE'> Nesta aba é possível verificar a lista de todos os utilizadores registados na plataforma.
        É disponibilizado várias opções de gerenciamento de usuário, de modo a ter um controle maior dos utilizadores.
        Poderá alterar as permissões do usuário, editar, entre outros.
      </h3>
      <img src={US} alt='US' id='imageGETwo' />

      <h3 id='segundoGE'> Poderá desativar a entidade caso ela já não faça parte da sua base de dados, alterar as suas permissões, editar as informações de contacto ou convidar
        a entidade para confirmação da sua conta no sistema.
      </h3>
      <img src={USActions} alt='USActions' id='imageGEThree' />

      {/*                                              UTILIZADORES SUBCONTACTOS                                                          */}

      <h1 id='tituloGESegundo'> || UTILIZADORES SUBCONTACTOS</h1>
      <h3 id='segundoGE'> Poderá verificar quais as entidades subcontactos existem na sua base de dados de forma geral. Dispõe das mesmas opções de desativação,
        edição e convite. Poderá filtrar os utilizadores pelo seu estado de ativação: Confirmados, Pendentes ou Bloqueados.
      </h3>
      <img src={USC} alt='USC' id='imageGEFour' />

      {/*                                              LOGS DE UTILIZADORES                                                          */}

      <h1 id='tituloGESegundo'> || LOGS DE UTILIZADORES</h1>
      <h3 id='segundoGE'> A aba de Logs de Utilizadores apresenta uma lista de utilizadores que fizeram modificações na plataforma.
        A criação de alguma tarefa/processo será registada. Assim, mostrará quem a fez, o IP, onde e o seu último acesso, mostrando a data e a hora.
      </h3>

      {/*                                              PERMISSÕES                                                          */}

      <h1 id='tituloGE'> | PERMISSÕES</h1>
      <h3 id='segundoGE'> Com base na definição da hierarquia, cada utilizador pode aceder a alguns módulos ou partes da plataforma.
        As permissões de cada utilizador são sempre definidas por aqueles que são superiores na plataforma.
        Por exemplo, um membro de equipa vê as suas permissões definidas pelo seu líder de equipa.
        Já o administrador pode dar e retirar permissões a qualquer utilizador da plataforma.
        As permissões são bastante importantes para que um utilizador só possa trabalhar com aquilo que realmente lhe compete, mas, por outro lado,
        o administrador consegue controlar o trabalho de todos os seus colaboradores. </h3>

      <h3 id='segundoGE'> Cada utilizador do sistema será redirecionado para a sua base de dados consoante os seus dados de acesso (email e password).
        Além disso, a base de dados fica sempre associada aos seus utilizadores, garantido que no futuro possam usufruir da mesma.
        Ainda dentro da questão de segurança, as passwords serão encriptadas dentro da base de dados, tendo apenas o utilizador o conhecimento da sua password.
      </h3>
      <img src={Permissoes} alt='Permissoes' id='imageGEFive' />


    </>
  )
}

export default GestaoEquipa;