import React from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import BDEntidades from "./pages/baseEntidades/index";
import Dashboard from "./pages/dashboard/index";
import Events from "./pages/eventos/index";
import FEmpresa from "./pages/fichaEmpresa/index";
import PagInicial from "./pages/paginaInicial/index";
import PFrequentes from "./pages/duvidasFrequentes/index";
import STecnico from "./pages/suporteTecnico/index";

import CRM from "./modulesPages/crm/index";
import Projects from "./modulesPages/projects/index";
import Credito from "./modulesPages/intermediarioCredito/index";
import Processual from "./modulesPages/processual/index";

import Classificadores from "./pages/configuracoes/classificadores/index";
import GestaoEquipa from "./pages/configuracoes/gestaoEquipa/index";
import Projectos from "./pages/configuracoes/projectos/index";



const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route element={<PagInicial />} exact path="/" />
                <Route element={<Dashboard />} exact path="/dashboard" />
                <Route element={<PFrequentes />} exact path="/perguntas-frequentes" />
                <Route element={<BDEntidades />} exact path="/base-dados-entidades" />
                <Route element={<FEmpresa />} exact path="/ficha-empresa" />
                <Route element={<Events />} exact path="/eventos" />
                <Route element={<STecnico />} exact path="/suporte-tecnico" />

                <Route element={<CRM />} exact path="/mod-crm" />
                <Route element={<Credito />} exact path="/mod-intermediario-credito" />
                <Route element={<Processual />} exact path="/mod-processual" />
                <Route element={<Projects />} exact path="/mod-projectos" />

                <Route element={<Classificadores />} exact path="/classificadores" />
                <Route element={<GestaoEquipa />} exact path="/gestao-equipa" />
                <Route element={<Projectos />} exact path="/projectos" />

            </Routes>
        </Router>
    )
}

export default AppRoutes;