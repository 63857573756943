import React from 'react';
import Navbar from '../../../components/Navbar';
import ButtonScrollUp from '../../../components/ButtonScrollUp';
import './index.css';

import Proj from '../../../images/Proj.png';
import TiposFluxo from '../../../images/TiposFluxo.png';
import EditFluxos from '../../../images/EditFluxos.png';
import NewFluxo from '../../../images/NewFluxo.png';
import PontoWeb from '../../../images/PontoWeb.png';
import Status from '../../../images/Status.png';
import Iva from '../../../images/Iva.png';
import Documentos from '../../../images/Documentos.png';
import Produtos from '../../../images/Produtos.png';
import TiposObra from '../../../images/TiposObra.png';
import Unidades from '../../../images/Unidades.png';
import DocOptions from '../../../images/DocOptions.png';

function Projectos() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              PROJECTOS                                                          */}

      <h1 id='tituloPR'> | PROJECTOS</h1>
      <h3 id='segundoPR'> Este módulo tem como objetivo definir vários pontos para as suas obras e projectos, de modo a conseguir identificá-los e/ou utilizá-los
        de uma forma mais eficaz. </h3>
      <img src={Proj} alt='Proj' id='imagePRTwo' />

      <h1 id='tituloPRSegundo'> || FLUXOS DE PRODUÇÃO</h1>
      <h3 id='segundoPR'> A plataforma permite que o utilizador crie vários tipos de fluxo de produção, de modo a conseguir ter uma produtividade mais ágil e um
        maior controlo das suas atividades.
        Poderá definir se o seu fluxo é de consumo ou material. A sua definição aparecerá posteriormente na criação de orçamento. </h3>
      <img src={TiposFluxo} alt='TiposFluxo' id='imagePROne' />

      <h3 id='segundoPR'> Caso deseje, poderá editar o fluxo e trocar o tipo.  </h3>
      <img src={EditFluxos} alt='EditFluxos' id='imagePRThree' />

      <h3 id='segundoPR'> Para adicionar, poderá clicar na opção de “Adicionar novo fluxo de produção” e preencher o pequeno formulário e definir o seu nome e tipo. Confirme para guardar.  </h3>
      <img src={NewFluxo} alt='NewFluxo' id='imagePRThree' />

      <h1 id='tituloPRSegundo'> || PONTO WEB</h1>
      <h3 id='segundoPR'> O Ponto Web é uma funcionalidade de registo de entrada e saida dos seus colaboradores dos seus projetos. Poderá verificar quem, quando entrou naquele
        determinado fluxo e verificar também de uma forma geral. Poderá analisar o custo total ou parcial dos projetos. Filtre por colaborador, por projeto, por fluxo ou por datas.
        Da forma que for mais eficaz para si!</h3>
      <img src={PontoWeb} alt='PontoWeb' id='imagePROne' />

      <h1 id='tituloPRSegundo'> || STATUS DE OBRAS</h1>
      <h3 id='segundoPR'> A plataforma disponibiliza ao usuário, a criação de estados para que possa acompanhar qual o progresso da sua obra. </h3>
      <img src={Status} alt='Status' id='imagePROne' />

      <h1 id='tituloPRSegundo'> || TAXAS DE IVA</h1>
      <h3 id='segundoPR'> Definir as taxas que serão anexadas em obras e/ou produtos. Poderá criar uma nova taxa, colocando a partir de que dia entra em vigor,
        estabelecer o IVA para o Continente, Madeira e Açores. Poderá alterar diretamente no painel as informações e serão guardadas automaticamente.
        Poderá sempre remover um período de taxas IVA, de modo a substituir por outro mais recente. </h3>
      <img src={Iva} alt='Iva' id='imagePROne' />

      <h1 id='tituloPRSegundo'> || TIPOS DE DOCUMENTOS</h1>
      <h3 id='segundoPR'> Defina os tipos de documentos que existem na sua organização, de modo, a conseguir posteriormente anexá-las ás obras e/ou projetos. </h3>
      <img src={Documentos} alt='Documentos' id='imagePROne' />

      <h3 id='segundoPR'> Poderá editar os documentos e definir a numeração inicial do documento, espaço fiscal, qual o tipo de movimentação e colocar uma pequena observação. </h3>
      <img src={DocOptions} alt='DocOptions' id='imagePRThree' />

      <h1 id='tituloPRSegundo'> || TIPOS DE PRODUTO</h1>
      <h3 id='segundoPR'> Defina os tipos de produtos existentes na sua organização, de modo, a conseguir posteriormente anexá-los ás obras e/ou projetos. </h3>
      <img src={Produtos} alt='Produtos' id='imagePROne' />


      <h1 id='tituloPRSegundo'> || TIPOS DE PROJECTO</h1>
      <h3 id='segundoPR'> Defina os tipos de projetos existentes na sua organização,de modo a conseguir identificá-los de uma maneira mais ágil. </h3>
      <img src={TiposObra} alt='TiposObra' id='imagePROne' />

      <h1 id='tituloPRSegundo'> || UNIDADES DE MEDIDA</h1>
      <h3 id='segundoPR'> Defina as unidades de medida existentes na sua organização, de modo, a conseguir posteriormente anexá-los ás obras e/ou projetos.
        Utilize-as para depois criar novos artigos e atribuir corretamente o seu peso. </h3>
      <img src={Unidades} alt='Unidades' id='imagePROne' />


    </>
  )
}

export default Projectos;