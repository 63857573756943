import React from 'react';
import '../Navbar/index.css';

function Navbar() {

  return (
    <>
      <div className="sidenav">
        <i className="material-icons" id='navIconTitle'>work</i><h1 className='titleMenu'>HELP SPOT4ALL</h1>
        <hr></hr>
        <a href="https://help.spot4all.com"><i className="material-icons" id='navIcons'>home</i>INICIO</a>
        <a href="https://help.spot4all.com/dashboard"><i className="material-icons" id='navIcons'>dashboard</i>DASHBOARD</a>
        <a href="https://help.spot4all.com/base-dados-entidades"><i className="material-icons" id='navIcons'>storage</i>BASE DADOS ENTIDADES</a>
        <a href="https://help.spot4all.com/mod-crm"><i className="material-icons" id='navIcons'>group_work</i>CRM</a>
        <a href="https://help.spot4all.com/mod-intermediario-credito"><i className="material-icons" id='navIcons'>euro</i>INTERMEDIÁRIO CRÉDITO</a>
        <a href="https://help.spot4all.com/mod-processual"><i className="material-icons" id='navIcons'>fact_check</i>PROCESSUAL</a>
        <a href="https://help.spot4all.com/mod-projectos"><i className="material-icons" id='navIcons'>work</i>PROJECTOS</a>
        <a href="https://help.spot4all.com/ficha-empresa"><i className="material-icons" id='navIcons'>groups</i>FICHA DA EMPRESA</a>

        <a href="https://help.spot4all.com/classificadores"><i className="material-icons" id='navIcons'>class</i>CLASSIFICADORES</a>
        <a href="https://help.spot4all.com/gestao-equipa"><i className="material-icons" id='navIcons'>group</i>GESTÃO DE EQUIPA</a>
        <a href="https://help.spot4all.com/projectos"><i className="material-icons" id='navIcons'>work</i>PROJECTOS</a>

        <a href="https://help.spot4all.com/eventos"><i className="material-icons" id='navIcons'>event_seat</i>EVENTOS</a>
        <a href="https://help.spot4all.com/suporte-tecnico"><i className="material-icons" id='navIcons'>browser_updated</i>ATUALIZAÇÕES</a>
        <a href="https://help.spot4all.com/perguntas-frequentes"><i className="material-icons" id='navIcons'>info_outline</i>DÚVIDAS FREQUENTES</a>
        <a href="https://help.spot4all.com/suporte-tecnico" className='support' id='support'>SUPORTE</a>
      </div>
    </>
  )


}

export default Navbar;