import React from 'react';
import Navbar from '../../components/Navbar';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import './index.css';

import BaseDados from '../../images/BaseDados.png';
import Entidades from '../../images/Entidades.png';
import SearchUsers from '../../images/SearchUsers.png';
import Actions from '../../images/Actions.png';

import User from '../../images/User.png';
import OptionBar from '../../images/OptionBar.png';
import Painel from '../../images/Painel.png';
import Transfer from '../../images/Transfer.png';
import TransferOne from '../../images/TransferOne.png';
import Confirmation from '../../images/Confirmation.png';
import IndividualTransfer from '../../images/IndividualTransfer.png';

import Share from '../../images/Share.png';
import Timeline from '../../images/Timeline.png';
import Nota from '../../images/Nota.png';
import Tarefas from '../../images/Tarefas.png';
import SMS from '../../images/SMS.png';
import Morada from '../../images/Morada.png';
import Familia from '../../images/Familia.png';
import Email from '../../images/Email.png';
import Docs from '../../images/Docs.png';
import Subcontatos from '../../images/Subcontatos.png';
import Edição from '../../images/Edição.png';

import Lista from '../../images/Lista.png';
import NewList from '../../images/NewList.png';
import NewListOne from '../../images/NewListOne.png';
import CreateList from '../../images/CreateList.png';
import Separador from '../../images/separador.png';
import Filtro from '../../images/Filtro.png';
import NameList from '../../images/NameList.png';

import Gerir from '../../images/Gerir.png';
import GerirLista from '../../images/GerirLista.png';
import CNCampanhas from '../../images/CNCampanhas.png';
import PAPerfil from '../../images/PAPerfil.png';
import ENACampanhas from '../../images/ENACampanhas.png';
import APlanoAcao from '../../images/APlanoAcao.png';
import APAcao from '../../images/APAcao.png';
import PAT from '../../images/PAT.png';
import PATTwo from '../../images/PATTwo.png';
import EBD from '../../images/EBD.png';


const baseEntidades = () => {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              BASE DE DADOS DE ENTIDADES                                                          */}

      <h1 id='tituloBd'> | BASE DE DADOS DE ENTIDADES</h1>
      <h3 id='segundoBd'> É neste menu que estão contidos todos os Contactos/Leads, assim como as listas onde estão inseridas.
        A partir da tabela da base de dados é possível adicionar tarefas a cada entidade, assim como editar, criar notas, assim como fazer filtros.
        Introduza novos Contactos ou Leads, para “alimentar” a sua base de dados.</h3>
      <img src={BaseDados} alt='Base de Dados' id='imageOne' />

      <h3 id='segundoBd'> Consiga pelo dropdown visualizar as suas entidades: </h3>
      <img src={Entidades} alt='Entidades' id='imageTwo' />

      <h3 id='segundoBd'> Faça a sua pesquisa e filtre os seus dados: </h3>
      <img src={SearchUsers} alt='Procura' id='imageThree' />

      <h3 id='segundoBd'> Em cada linha de contacto/lead visualizamos do lado direito uns ícones que podemos designar por ações: </h3>
      <h3 id='segundoBd'> Adicionar tarefa, Adicionar processo, Editar contacto e Adicionar nota. </h3>
      <img src={Actions} alt='Ações' id='imageFour' />

      {/*                                                   FICHA DE CONTACTO                                                              */}

      <h1 id='tituloBdSegundo'> || FICHA DO CONTACTO</h1>
      <h3 id='segundoBd'> Após ter gravado o contacto/lead, clique no contacto para visualizarmos a vista completa do perfil. </h3>
      <img src={User} alt='Users' id='imageFive' />

      <h3 id='segundoBd'> É possível alterar ou criar alguns detalhes respetivamente a este contacto. Poderá visualizar um pequeno histórico de criações (Timeline) e
        modificações para aquele respectivo contacto.
        Poderá adicionar uma nota, uma tarefa, alteração de morada, família, poderá agendar ou enviar uma SMS ou um email, selecionar documentos para visualização,
        adicionar e visualizar subcontactos e adicionar alguns campos personalizados. </h3>
      <img src={OptionBar} alt='Barra de opções de contacto' id='imageSix' />

      <h3 id='segundoBd'> Do lado direito é possível observar se estão atribuídas atividades em curso, Planos de Ação ou Ficheiros ao contacto/lead que está a visualizar: </h3>
      <img src={Painel} alt='Painel' id='imageTwo' />

      {/*                                                   FICHA DE CONTACTO                                                              */}

      <h1 id='tituloBdSegundo'> || TRANSFERÊNCIA DE CONTACTO PARA LEAD</h1>
      <h3 id='segundoBd'> Para a passagem de contacto para lead ou de lead para contacto basta selecionar a opção abaixo: </h3>
      <img src={Transfer} alt='Transferência de Cont para Lead' id='imageTwo' />

      <h3 id='segundoBd'> Após selecionar a opção de transferência, poderá modificar os campos de Classificação e Tipo de Entidade.
        Caso os campos sejam os mesmos, não faça nenhuma alteração e clique somente em Confirmar. </h3>
      <img src={TransferOne} alt='Transferência de Cont para Lead' id='imageSeven' />
      <img src={Confirmation} alt='Confirmation' id='imageTwelve' />

      <h3 id='segundoBd'> Caso pretenda modificar os campos individualmente, poderá somente clicar nas setas e efetuar a sua alteração individualmente.
        Clique sempre em Confirmar para guardar as suas alterações. </h3>
      <img src={IndividualTransfer} alt='Transferência individual' id='imageTwo' />

      {/*                                                        PARTILHA                                                                   */}

      <h1 id='tituloBdSegundo'> || PARTILHAR</h1>
      <h3 id='segundoBd'> Os gestores podem ainda partilhar cada contacto/lead entre gestores. Basta dentro do perfil da lead e clicar no (+) para conseguir partilhar: </h3>
      <img src={Share} alt='Partilha' id='imageTwo' />

      {/*                                                        TIMELINE                                                                   */}

      <h1 id='tituloBdSegundo'> || TIMELINE</h1>
      <h3 id='segundoBd'> Tudo o que está associado ao contacto/lead está disposto nesta timeline.
        Todas as interações, notas, tarefas aparecem na timeline para uma melhor perceção de todo o historial do cliente. </h3>
      <img src={Timeline} alt='Timeline' id='imageEight' />


      {/*                                                        NOVA NOTA                                                                  */}

      <h1 id='tituloBdSegundo'> || NOVA NOTA</h1>
      <h3 id='segundoBd'> Poderá preencher uma nota no menu do contacto específico ou até mesmo adicionar uma nota na Base de Dados Entidade como anteriormente referido.
        A plataforma disponibiliza opções de edição e eliminação caso a nota já tenha sido criada.</h3>
      <img src={Nota} alt='Nota' id='imageEight' />

      {/*                                                          TAREFAS                                                                   */}

      <h1 id='tituloBdSegundo'> || TAREFAS</h1>
      <h3 id='segundoBd'> Outra forma de criar tarefas é abrir o perfil completo de um usuário. Lá,poderá criar tarefas especificas para aquele usuário.
        O formulário de preenchimento é 100% igual ao do Dashboard.</h3>
      <img src={Tarefas} alt='Tarefas' id='imageEight' />

      {/*                                                          MORADA                                                                    */}

      <h1 id='tituloBdSegundo'> || MORADA</h1>
      <h3 id='segundoBd'> A plataforma disponibiliza uma visualização dos dados de residência ou trabalho do usuário. No entanto, não será possivel alterar os dados.
        Caso deseje alterar os dados, deverá editar a entidade. Poderá consultar a edição de entidade mais abaixo. </h3>
      <img src={Morada} alt='Morada' id='imageEight' />

      {/*                                                          FAMÍLIA                                                                   */}

      <h1 id='tituloBdSegundo'> || FAMÍLIA</h1>
      <h3 id='segundoBd'> A plataforma disponibiliza a opção de criação de uma família para uma melhor gestão de relações familiares.
        Poderá relacionar entidades e a sua relação será demonstrada na lista. </h3>
      <img src={Familia} alt='Familia' id='imageEight' />


      {/*                                                        ENVIAR SMS                                                                  */}

      <h1 id='tituloBdSegundo'> || SMS</h1>
      <h3 id='segundoBd'> Poderá enviar uma mensagem diretamente para um contacto. Preencha o campo e em seguida, será solicitado que selecione para quem se destina este SMS.
        Em baixo, posteriormente, será possível visualizar um histórico de mensagens e quantas foram enviadas para ter uma melhor gestão. </h3>
      <img src={SMS} alt='SMS' id='imageEight' />

      {/*                                                           EMAIL                                                                    */}

      <h1 id='tituloBdSegundo'> || EMAIL</h1>
      <h3 id='segundoBd'> Poderá enviar um email diretamente para um contacto. A plataforma disponibiliza várias opções de estilização do email,
        bastante semelhante ao Outlook. Note que este método de envio, é somente para emails esporádicos, que não necessitem de serem enviados a cada x tempo.
        Defina o assunto do email, crie o seu corpo e caso deseje, anexe ficheiros ao mesmo. Em seguida, clique em "Enviar". Poderá verificar para qual email será enviado
        o email.</h3>
      <img src={Email} alt='Email' id='imageEight' />

      {/*                                                         DOCUMENTOS                                                                 */}

      <h1 id='tituloBdSegundo'> || DOCUMENTOS</h1>
      <h3 id='segundoBd'> Será possível pré-visualizar os documentos anexados respetivamente aquele contacto.
        Poderá filtrar de acordo com o seu desejo. A plataforma disponibiliza ações para cada documento, sendo elas: a opção de remoção e a opção de edição. </h3>
      <img src={Docs} alt='Documentos' id='imageEight' />

      {/*                                                         SUBCONTACTOS                                                               */}

      <h1 id='tituloBdSegundo'> || SUBCONTACTOS</h1>
      <h3 id='segundoBd'> A plataforma dá a possibilidade de adicionar subcontactos a um contacto específico. Poderá gerir o sub contacto com as seguintes ações:
        Convidar, Editar e Remover. </h3>
      <img src={Subcontatos} alt='Subcontatos' id='imageEight' />

      {/*                                                       EDIÇÃO DE ENTIDADE                                                           */}

      <h1 id='tituloBdSegundo'> || EDIÇÃO DE ENTIDADE</h1>
      <h3 id='segundoBd'> Como referido anteriomente, poderá editar os dados de uma entidade através do seu perfil completo. No canto superior direito, ao lado
        da imagem da entidade, verfique a opção de edição caracterizada por um icone de lápis. </h3>
      <img src={Edição} alt='Edição' id='imageTwo' />
      <h3 id='segundoBd'> Ao abrir a edição, será aberto o separador de contacto, igual à página de criação de contacto.
        Será somente necessário alterar os campos desejados e clicar em guardar. </h3>

      {/*                                                            LISTAS                                                                  */}

      <h1 id='tituloBd'> | LISTAS</h1>
      <h3 id='segundoBd'> A plataforma permite a criação de listas, de modo a organizar os seus contactos e a que cada um deles corresponde. </h3>
      <img src={Lista} alt='Lista' id='imageEight' />

      <h1 id='tituloBdSegundo'> || CRIAÇÃO DE LISTAS</h1>
      <h3 id='segundoBd'> Para iniciar a criação de uma lista, clique em "Nova Lista" e selecione o tipo de lista e
        para qual base de dados gostaria que essa lista estivesse visivel. Poderá escolher a opção de "Todos" para uma base de dados global ou escolher um gestor
        caso a lista seja pessoal ou especifica para o efeito. Poderá escolher a sua lista como sendo Dinâmica
        (Atualizada automaticamente, caso os usuários sejam compativeis com os filtros da lista) ou Fixa (Se for uma lista permanente, sem alterações futuras.)</h3>
      <img src={NewList} alt='NewList' id='imageEight' />
      <img src={CreateList} alt='CreateList' id='imageTen' />

      <h3 id='segundoBd'> Ao criar uma lista, poderá selecionar diretamente a que campanha deseja que essa lista esteja associada.
        Se não desejar, poderá deixar a opção padrão de “Sem Campanha Selecionada”.</h3>
      <img src={NewListOne} alt='NewListOne' id='imageEleven' />


      {/*                                                            FILTROS                                                                 */}

      <h1 id='tituloBdSegundo'> ||| FILTROS</h1>
      <h3 id='segundoBd'> Para escolher usuários específicos para essa lista, defina filtros para filtrar esses usuários. </h3>
      <img src={Filtro} alt='Filtro' id='imageSeven' />
      <h3 id='segundoBd'> Ao clicar num filtro específico, será-lhe adicionada uma opção de “Operador”, ela permite-lhe filtrar os usuários de uma maneira mais dinâmica.
        Caso não se lembre que o usuário se chama João mas que contém um J, poderá filtrar por “Contém” ou “Começa por”.
        Não se esqueça de digitar o que deseja pesquisar, no campo de Pesquisa. Em seguida, clique em Aplicar. </h3>


      <h3 id='segundoBd'> Após a filtração de entidades, clique em Guardar. Ser-lhe-á apresentada uma nova janela para dar um nome a essa lista.
        Poderá colocar o nome da empresa ao qual esses usuários pertencem, localidades, etc. </h3>
      <img src={NameList} alt='NameList' id='imageSeven' />

      <h3 id='segundoBd'> Após a criação da lista, poderá visualizar a mesma na aba de listas. Terá o nome que definiu e o tipo de lista escolhido.</h3>
      <img src={Separador} alt='Separador' id='imageEight' />

      {/*                                                          GERIR LISTAS                                                               */}

      <h1 id='tituloBdSegundo'> || GERIR LISTAS</h1>
      <h3 id='segundoBd'> Após a criação da lista, será apresentada uma nova opção: </h3>
      <img src={Gerir} alt='Gerir' id='imageEight' />

      <h3 id='segundoBd'>  Poderá exportar, associar a lista a uma campanha ou até mesmo removê-la: </h3>
      <img src={GerirLista} alt='GerirLista' id='imageThirteen' />

      {/*                                                 CONTACTOS NÃO ASSOCIADOS A CAMPANHAS                                                */}

      <h1 id='tituloBdSegundo'> || CONTACTOS NÃO ASSOCIADOS A CAMPANHAS</h1>
      <h3 id='segundoBd'> Existe a opção de filtrar os contatos não associados a nenhuma campanha.
        Poderá clicar para conseguir ter um melhor controle de quem faz parte ou não e assim adicionar posteriormente. </h3>
      <img src={CNCampanhas} alt='CNCampanhas' id='imageFourteen' />

      <h3 id='segundoBd'> Para adicionar um contacto individual a uma campanha, clique no contacto para abrir o seu perfil completo.
        Em seguida, no canto direito do perfil, clique em Planos de Ação.</h3>
      <img src={PAPerfil} alt='PAPerfil' id='imageThirteen' />
      <h3 id='segundoBd'> Poderá ver se o usuário está em algum plano de ação. Se não estiver, aparecerá vazio.
        Clique em “Associar a plano de ação” e escolha qual o plano que o usuário pertencerá.
        Neste caso, o contacto Teste está incluso no Plano de Ação “titulo-teste”.</h3>
      <img src={ENACampanhas} alt='ENACampanhas' id='imageEight' />

      {/*                                                       ASSOCIAR A UM PLANO DE AÇÃO                                                   */}

      <h1 id='tituloBdSegundo'> || ASSOCIAR A UM PLANO DE AÇÃO</h1>
      <h3 id='segundoBd'> Existe outra maneira de associar um ou mais usuários a uma campanha.
        Para tal, terá de selecionar o(s) contacto(s) que deseja associar a um plano de ação, clique na opção e será solicitado que selecione o plano. </h3>
      <img src={APlanoAcao} alt='APlanoAcao' id='imageThirteen' />
      <img src={APAcao} alt='APAcao' id='imageFifthteen' />

      <h3 id='segundoBd'> Clique em confirmar para prosseguir e pronto, o contacto que selecionou já está no seu plano de ação.
        Para confirmar a sua existência, vá no CRM &rarr; Planos de Ação e selecione o Plano de Ação Intemporal ou Temporal que definiu na associação.
        No Plano de Ação Temporal, onde foi inserido o contacto Teste, clique em cima da opção de pessoa: </h3>
      <img src={PAT} alt='PAT' id='imageSeventeen' />

      <h3 id='segundoBd'> Ao abrir, visualiza as entidades associadas a esse plano e até mesmo, as listas associadas. </h3>
      <img src={PATTwo} alt='PATTwo' id='imageSixteen' />

      {/*                                                       EXPORTAÇÃO DA BASE DE DADOS                                                   */}

      <h1 id='tituloBdSegundo'> || EXPORTAÇÃO DA BASE DE DADOS</h1>
      <h3 id='segundoBd'> Poderá exportar a sua base de dados de forma a tê-la atualizada.
        Poderá exportar a sua base de dados periodicamente para uma maior segurança e backup dos seus dados. </h3>
      <img src={EBD} alt='EBD' id='imageTwo' />




    </>
  )
}

export default baseEntidades;