import React from 'react';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import Navbar from '../../components/Navbar';
import '../duvidasFrequentes/index.css';

function PFrequentes() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              PERGUNTAS FREQUENTES                                                             */}
      <h1 id='tituloFreq'> | DÚVIDAS FREQUENTES</h1>
      <h3 id='segundoFreq'> Caso seja um novo utilizador do CRM, seja bem-vindo/a! As Dúvidas Frequentes servirão como um auxiliar para conseguir desfrutar do CRM sem qualquer problema.
        Serão apresentadas as dúvidas mais frequentes e as suas respostas descritas num passo a passo para uma utilização inicial mais eficiente. </h3>

      <div className='questionOne'>
        <h1 id='tituloFreqSegundo'> Como criar uma lista? </h1>
        <h3 id='primeiroFreq'><ul>
          <li> 1. Crie um contacto ou uma lead.</li>
          <li> 2. Vá na Base Dados Entidade e clique em "Nova Lista."</li>
          <li> 3. Selecione se a sua lista será de entidades ou subcontactos e se será visualizada por todos ou somente por si.</li>
          <li> 4. Crie filtros para filtrar as entidades e caso não queira, clique somente em Guardar.</li>
          <li> 5. Dê um nome á sua lista e escolha se será dinâmica ou fixa.</li>
        </ul>
        </h3>
      </div>

      <div className='questionTwo'>
        <h1 id='tituloFreqSegundo'> Como enviar um Email/Sms numa determinada data ou hora? </h1>
        <h3 id='primeiroFreq'>
          <ul>
            <li> 1. Crie templates para o email ou para o Sms no CRM.</li>
            <li> 2. Crie um plano de ação temporal (com data definida) ou intemporal (sem data).</li>
            <li> 3. Crie os passos necessários para esse plano e escolha entre opção de email ou sms.</li>
            <li> 4. Escolha o template que irá utilizar, defina quando e o assunto.</li>
            <li> 5. Vá na Base Dados Entidade e clique na opção "Gerir Listas".</li>
            <li> 6. Escolha para qual lista de entidades será enviada essa ação e associe á Campanha/Plano de Ação.</li>
          </ul>
        </h3>
      </div>

      <div className='questionThree'>
        <h1 id='tituloFreqSegundo'> Como criar um projeto? </h1>
        <h3 id='primeiroFreq'>
          <ul>
            <li> 1. Defina todos os seus parâmetros nas Configurações, em seguida Classificadores e Projetos.</li>
            <li> 2. Defina também os seus artigos caso deseje e os documentos financeiros no módulo Projectos.</li>
            <li> 3. Após definir todos os parâmetros, clique em Projectos, em seguida Áreas e em seguida, Comercial.</li>
            <li> 4. Em seguida, clique em "Nova Obra".</li>
            <li> 5. Preencha todos os campos relativamente á sua obra e grave.</li>
            <li> 6. Após isso, na Listagem de Obras, clique na segunda Ação caracterizada por uma lupa.</li>
            <li> 7. Após clicar, na primeira barra azul, clique em "Ir para Obra".</li>
            <li> 8. Adicione os fluxos de produção definidos anteriormente e preencha com as devidas horas previstas e as datas solicitadas.</li>
            <li> (Os seus colaboradores entrarão no projeto através da marcação do Ponto Web)</li>
            <li> 10. Após toda a configuração, retorne á listagem de obras e dê inicio á mesma. Poderá ir visualizando um pequeno resumo ao clicar na segunda ação.</li>
          </ul>
        </h3>
      </div>

      <div className='questionFour'>
        <h1 id='tituloFreqSegundo'> Como criar tarefa e atribuir? </h1>
        <h3 id='primeiroFreq'>
          <ul>
            <li> 1. Vá ao Dashboard</li>
            <li> 2. No painel de tarefas, clique na opção de "+".</li>
            <li> 3. Ao clicar, aparecerá o formulário de criação de tarefa.</li>
            <li> 4. Preencha e antes de confirmar, tem uma opção de "Atribuir ao utilizador". Escolha a quem pretenda atribuir essa tarefa.</li>
            <li> 5. Clique em "Confirmar" e a entidade em questão terá a sua tarefa no perfil.</li>
          </ul>
        </h3>
      </div>
      <div className='questionFive'>
        <h1 id='tituloFreqSegundo'> Como criar um orçamento? </h1>
        <h3 id='primeiroFreq'>
          <ul>
            <li> 1. Defina os parâmetros necessários em Configurações.</li>
            <li> 2. Após definir, vá em Projectos &rarr; Áreas &rarr; Orçamentos. </li>
            <li> 3. Clique em "Novo Orçamento" e preencha o formulário. A obra será criada automaticamente em seguida.</li>
            <li> 4. Após guardar, dirija-se á listagem de obras e adicione os fluxos e os materiais necessários. Anexe documentos caso seja pertinente. </li>
            <li> 5. Em seguida, clique em "Ir para Obra" no canto superior direito. </li>
            <li> 6. No painel de Fluxo de Materiais, adicione os materiais, as quantidades e os valores.  </li>
            <li> 7. No painel de Fluxo de Produção, adicione os fluxos, as horas previstas e custos. Poderá adicionar horas de trabalho e atribuir a um colaborador e verificar
              quantas horas um colaborador especifico esteve a trabalhar naquele fluxo. </li>
            <li> 8. Inicie os fluxos de produção e inicie a obra após estar tudo configurado. </li>
          </ul>
        </h3>
      </div>


    </>
  )
}

export default PFrequentes;