import React from 'react';
import Navbar from '../../../components/Navbar';
import ButtonScrollUp from '../../../components/ButtonScrollUp';
import './index.css';

import Class from '../../../images/Class.png';
import ClassOne from '../../../images/ClassOne.png';
import Campos from '../../../images/Campos.png';
import Cargos from '../../../images/Cargos.png';
import DocsEntidade from '../../../images/DocsEntidade.png';
import DocsGenericos from '../../../images/DocsGenericos.png';
import Fontes from '../../../images/Fontes.png';
import Gerais from '../../../images/Gerais.png';
import Probs from '../../../images/Probs.png';
import Saudacoes from '../../../images/Saudacoes.png';
import TiposStatus from '../../../images/TiposStatus.png';
import Tags from '../../../images/Tags.png';
import TagsGlobais from '../../../images/TagsGlobais.png';
import TiposEntidade from '../../../images/TiposEntidade.png';
import TiposTarefas from '../../../images/TiposTarefas.png';

function Classificadores() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              CLASSIFICADORES                                                          */}

      <h1 id='tituloCL'> | CLASSIFICADORES</h1>
      <h3 id='segundoCL'> Os Classificadores é uma categoria do módulo de Configurações. Ele vai auxiliar-lhe a si, entidade, a configurar vários aspetos do seu CRM.
        Defina os Status das obras, os documentos, os cargos, os tipos de tarefas, entre outros. Ao serem definidos os nomes para os tipos de Classificadores,
        estes já se vão encontrar disponíveis e prontos a adicionar no separador designado (base de dados entidades, nos módulos, etc.).
        Neste menu é possível definir uma série de características que vão definir toda a dinâmica para a sua gestão da sua empresa. </h3>
      <img src={Class} alt='Class' id='imageCLOn' />
      <img src={ClassOne} alt='ClassOne' id='imageCLOn' />

      <h1 id='tituloCLSegundo'> || CAMPOS PERSONALIZADOS</h1>
      <h3 id='segundoCL'> Defina campos personalizados de uma forma geral. Poderá definir campos de tipos de dados, sejam eles números, texto, dados, entre outros. Terá de criar
        em primeira mão, o grupo e somente em seguida, poderá criar os campos. </h3>
      <img src={Campos} alt='Campos' id='imageCLOne' />


      <h1 id='tituloCLSegundo'> || CARGOS</h1>
      <h3 id='segundoCL'> Defina cargos para atribuir aos seus colaboradores. Poderá definir de acordo com o departamento ou de acordo com a sua respetiva profissão. </h3>
      <img src={Cargos} alt='Cargos' id='imageCLOne' />


      <h1 id='tituloCLSegundo'> || DOCUMENTOS DE ENTIDADE</h1>
      <h3 id='segundoCL'> Defina documentos especificos para cada entidade que colabore com a sua empresa.
        Poderá definir somente documentos que realmente sejam especificos para o tipo de negócio. </h3>
      <img src={DocsEntidade} alt='DocsEntidade' id='imageCLFour' />

      <h1 id='tituloCLSegundo'> || DOCUMENTOS GENÉRICOS</h1>
      <h3 id='segundoCL'> Defina documentos genéricos para todas as entidades que colaborem com a sua empresa. </h3>
      <img src={DocsGenericos} alt='DocsGenericos' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || FONTES DE CONTACTO</h1>
      <h3 id='segundoCL'> Crie grupos de contactos, de modo a identificar melhor os contactos na sua empresa. Poderá criar de acordo com os cargos e associar os contactos. </h3>
      <img src={Fontes} alt='Fontes' id='imageCLTwo' />


      <h1 id='tituloCLSegundo'> || GERAIS</h1>
      <h3 id='segundoCL'> Permite identificar os seus contactos e os seus colaboradores de uma forma mais ágil.
        Pode servir como uma maneira de identificar quais colaboradores podem ter acesso ao que.</h3>
      <img src={Gerais} alt='Gerais' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || PROBABILIDADES FINANCEIRO</h1>
      <h3 id='segundoCL'> Poderá identificar como os estados dos seus pedidos, documentação, obras, entre outros.</h3>
      <img src={Probs} alt='Probs' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || SAUDAÇÕES</h1>
      <h3 id='segundoCL'> Crie saudações para identificar de forma mais respeitosa os seus clientes ou colaboradores.</h3>
      <img src={Saudacoes} alt='Saudacoes' id='imageCLFour' />

      <h1 id='tituloCLSegundo'> || STATUS</h1>
      <h3 id='segundoCL'> Crie status para os seus processos de forma a conseguir acompanhar com mais agilidade como está os seus processos.</h3>
      <img src={TiposStatus} alt='TiposStatus' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || TAGS</h1>
      <h3 id='segundoCL'> Crie status para os seus processos de forma a conseguir acompanhar com mais agilidade como está os seus processos.</h3>
      <img src={Tags} alt='Tags' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || TAGS GLOBAIS</h1>
      <h3 id='segundoCL'> Possibilidade de criação de TAGS GLOBAIS para todas as bases de dados existentes na plataforma, de modo a identificar os aspetos comuns.</h3>
      <img src={TagsGlobais} alt='TagsGlobais' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || TIPOS DE ENTIDADE</h1>
      <h3 id='segundoCL'> Possibilidade de identificar os tipos de entidades com que a sua empresa colabora.</h3>
      <img src={TiposEntidade} alt='TiposEntidade' id='imageCLTwo' />

      <h1 id='tituloCLSegundo'> || TIPOS DE TAREFAS</h1>
      <h3 id='segundoCL'> Possibilidade de identificação de tarefas, de modo a obter uma melhor organização na criação das mesmas e a sua visualização posteriormente.</h3>
      <img src={TiposTarefas} alt='TiposTarefas' id='imageCLThree' />



    </>
  )
}

export default Classificadores;