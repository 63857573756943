import React from 'react';
import logo from '../../images/logoS4A.png';
import questions from '../../images/questions.svg';
import '../NavbarInicial/index.css';


function NavbarInicial() {
  return (
    <>
      <main className="main">
        <div className="sidenavInitial">
          <i className="material-icons" id='navIconTitleInitial'>work</i><h1 className='titleMenuInitial'>HELP SPOT4ALL</h1>
          <hr></hr>
          <a href="https://help.spot4all.com"><i className="material-icons" id='navIconsInitial'>home</i>INICIO</a>
          <a href="https://help.spot4all.com/dashboard"><i className="material-icons" id='navIconsInitial'>dashboard</i>DASHBOARD</a>
          <a href="https://help.spot4all.com/base-dados-entidades"><i className="material-icons" id='navIconsInitial'>storage</i>BASE DADOS ENTIDADES</a>
          <a href="https://help.spot4all.com/mod-crm"><i className="material-icons" id='navIconsInitial'>group_work</i>CRM</a>
          <a href="https://help.spot4all.com/mod-intermediario-credito"><i className="material-icons" id='navIconsInitial'>euro</i>INTERMEDIÁRIO CRÉDITO</a>
          <a href="https://help.spot4all.com/mod-processual"><i className="material-icons" id='navIconsInitial'>fact_check</i>PROCESSUAL</a>
          <a href="https://help.spot4all.com/mod-projectos"><i className="material-icons" id='navIconsInitial'>work</i>PROJECTOS</a>
          <a href="https://help.spot4all.com/ficha-empresa"><i className="material-icons" id='navIconsInitial'>groups</i>FICHA DA EMPRESA</a>

          <a href="https://help.spot4all.com/classificadores"><i className="material-icons" id='navIconsInitial'>class</i>CLASSIFICADORES</a>
          <a href="https://help.spot4all.com/gestao-equipa"><i className="material-icons" id='navIconsInitial'>group</i>GESTÃO DE EQUIPA</a>
          <a href="https://help.spot4all.com/projectos"><i className="material-icons" id='navIconsInitial'>work</i>PROJECTOS</a>

          <a href="https://help.spot4all.com/eventos"><i className="material-icons" id='navIconsInitial'>event_seat</i>EVENTOS</a>
          <a href="https://help.spot4all.com/suporte-tecnico"><i className="material-icons" id='navIconsInitial'>browser_updated</i>ATUALIZAÇÕES</a>
          <a href="https://help.spot4all.com/perguntas-frequentes"><i className="material-icons" id='navIconsInitial'>info_outline</i>DÚVIDAS FREQUENTES</a>
          <a href="https://help.spot4all.com/suporte-tecnico" className='support' id='supportInitial'>SUPORTE</a>
        </div>

        <section className="spot">
          <div className="container">
            <a target="_blank" href="https://spot4all.com" rel="noreferrer">
              <img className="social" src={logo} alt='logo' />
            </a>
            <div className='contacto'>
              <i className="large material-icons" id="phoneInitial">phone</i>
              <a href="tel:00351265548750" id='contactInitial'>(+351) 265 548 750</a>
            </div>
            <div className='email'>
              <i className="large material-icons" id="mailInitial">mail</i>
              <a href="mailto:info@spot4all.com" id='contactInitial'>info@spot4all.com</a>
            </div>

            <div className='steps'>
              <a href="https://help.spot4all.com/perguntas-frequentes"><img src={questions} alt='questions' id='questions' /></a>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default NavbarInicial;