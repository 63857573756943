import React from 'react';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import Navbar from '../../components/Navbar';
import './index.css';

import Projectos from '../../images/Projectos.png';
import Artigos from '../../images/Artigos.png';
import NewArtigo from '../../images/NewArtigo.png';
import DocFinanceiros from '../../images/DocFinanceiros.png';
import NewDoc from '../../images/NewDoc.png';
import Areas from '../../images/Areas.png';
import Obra from '../../images/Obra.png';
import NewObra from '../../images/NewObra.png';
import ObraActions from '../../images/ObraActions.png';
import Anexos from '../../images/Anexos.png';
import FichaCliente from '../../images/FichaCliente.png';
import AreaComercial from '../../images/AreaComercial.png';
import Analise from '../../images/Analise.png';
import AnaliseTwo from '../../images/AnaliseTwo.png';
import NavActions from '../../images/NavActions.png';
import Fluxo from '../../images/Fluxo.png';
import AddFluxo from '../../images/AddFluxo.png';
import EditFluxo from '../../images/EditFluxo.png';
import ConfigFluxo from '../../images/ConfigFluxo.png';
import Materiais from '../../images/Materiais.png';
import AnaliseOption from '../../images/AnaliseOption.png';
import Grafico from '../../images/Grafico.png';
import Tecnica from '../../images/Tecnica.png';
import Lançamentos from '../../images/Lançamentos.png';
import Total from '../../images/Total.png';
import Orcamento from '../../images/Orcamento.png';
import OrcFluxos from '../../images/OrcFluxos.png';
import OrcActions from '../../images/OrcActions.png';
import OrcFichas from '../../images/OrcFichas.png';
import OrcObra from '../../images/OrcObra.png';
import OrcAnalise from '../../images/OrcAnalise.png';
import OrcAnaliseTwo from '../../images/OrcAnaliseTwo.png';
import OrcDocs from '../../images/OrcDocs.png';
import OrcDocsTwo from '../../images/OrcDocsTwo.png';
import OrcFluxosTwo from '../../images/OrcFluxosTwo.png';



function Projects() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              PROJECTOS                                                          */}

      <h1 id='tituloProj'> | PROJECTOS</h1>
      <h3 id='segundoProj'> Este módulo tem como objetivo o registo de toda a atividade relacionada com o processo de comunicação da base de dados inserida no portal. </h3>
      <img src={Projectos} alt='Projectos' id='imageProjOne' />

      {/*                                               ARTIGOS                                                       */}

      <h1 id='tituloProjSegundo'> || ARTIGOS</h1>
      <h3 id='segundoProj'> Esta subcategoria permite ao utilizador, registar artigos que façam parte da sua empresa. </h3>
      <img src={Artigos} alt='Artigos' id='imageProjTwo' />

      <h3 id='segundoProj'> Poderá criar uma lista dos seus artigos individuais que compõem a sua empresa! Ao clicar no botão de "Adicionar Novo Artigo",
        será aberto um formulário de preenchimento, onde poderá colocar várias informações relativos aquele produto, uma fotografia para a sua melhor identificação, o seu custo,
        entre outras opções. </h3>
      <img src={NewArtigo} alt='NewArtigo' id='imageProjThree' />

      {/*                                         DOCUMENTOS FINANCEIROS                                                       */}

      <h1 id='tituloProjSegundo'> || DOCUMENTOS FINANCEIROS</h1>
      <h3 id='segundoProj'> Permite ao utilizador registar documentos de finanças na plataforma. Com os mesmos, poderá anexar a uma obra referida anteriormente.
        Poderá filtrar por tipos de documento e/ou pelos projetos já existentes. Os tipos de documento poderá defini-los na aba de Configurações &rarr; Classificadores. </h3>
      <img src={DocFinanceiros} alt='DocFinanceiros' id='imageProjFour' />

      <h3 id='segundoProj'> Após a criação dos Classificadores, poderá criar um novo documento. Nele, selecione o tipo de documento, a data , o fornecedor, o número de documento
        e o projeto a que deseja associar esse documento. </h3>
      <img src={NewDoc} alt='NewDoc' id='imageProjTwo' />

      <h3 id='segundoProj'> Poderá também, verificar os valores totais do documento que selecionou. </h3>
      <img src={Total} alt='Total' id='imageProjThirdteen' />

      <h3 id='segundoProj'> Terá a possibilidade de adicionar um novo lançamento de artigo neste documento em especifico! Defina
        em qual projeto estará associado, os seus valores, quantidades e descontos caso se aplique. Poderá editar ou remover Lançamentos
        já existentes no documento.</h3>
      <img src={Lançamentos} alt='Lançamentos' id='imageProjFourteen' />

      {/*                                              ÁREAS                                                          */}

      <h1 id='tituloProj'> | ÁREAS</h1>
      <h3 id='segundoProj'> As áreas permitem á entidade definir vários campos que vão caracterizar os seus projetos e/ou obras de uma forma mais organizada e controlada.
        Gerencie os tempos dos seus fluxos, custos previstos, entre outras funcionalidades. </h3>
      <img src={Areas} alt='Areas' id='imageProjFive' />

      {/*                                            ORÇAMENTOS                                                       */}

      <h1 id='tituloProjSegundo'> | ÁREA ORÇAMENTAL</h1>
      <h3 id='segundoProj'> Permite ter um controle orçamental da sua obra, nomeadamente dos custos previstos em cada fluxo e o custo previsto total e/ou parcial dos materiais necessários para a sua conclusão.
        Dispõe de opções de adicionar fluxos e materiais previamente definidos.
        Poderá filtrar projetos já existentes pelo seu status.</h3>
      <img src={Orcamento} alt='Orcamento' id='imageProjFifthteen' />

      {/*                                            CRIAÇÃO DE UM ORÇAMENTO                                                      */}

      <h1 id='tituloProjSegundo'> || CRIAÇÃO DE UM ORÇAMENTO</h1>
      <h3 id='segundoProj'> Para criação do orçamento, terá de preencher um pequeno formulário ao clicar na opção "Novo Orçamento".
        Ao clicar, preencha os dados do formulário com as informações do seu orçamento e atribua um ID à mesma.
        Ao preencher o formulário para criação de um orçamento, automaticamente já será gerada uma obra associada a este orçamento.  </h3>
      <img src={NewObra} alt='NewObra' id='imageProjSix' />

      {/*                                                 AÇÕES                                                       */}

      <h1 id='tituloProjSegundo'> || AÇÕES</h1>
      <h3 id='segundoProj'> Existem ações que a plataforma disponibiliza para conseguir gerir as suas obras da melhor forma.
        A primeira opção permite á entidade adicionar fluxos previamente definidos nas configurações. </h3>
      <img src={OrcFluxos} alt='OrcFluxos' id='imageProjSix' />

      <h3 id='segundoProj'> A segunda opção permite á entidade adicionar materiais previamente definidos e a terceira opção permite que a entidade se redirecione para a sua obra.</h3>
      <img src={OrcActions} alt='OrcActions' id='imageProjEleven' />

      <h3 id='segundoProj'> Ao clicar para "Ir para a sua obra", poderá verificar a ficha do cliente e a área comercial, onde poderá fazer modificações dos dados já inseridos anteriormente.</h3>
      <img src={OrcFichas} alt='OrcFichas' id='imageProjTwo' />

      <h3 id='segundoProj'> Poderá verificar mais abaixo, o orçamento da obra que demonstra o total de horas de fluxos, materiais e as quantidades.
        Tem a possibilidade de verificar a quantidade, o valor e o total para cada fluxo e material em específico.</h3>
      <img src={OrcObra} alt='OrcObra' id='imageProjSix' />

      <h3 id='segundoProj'> A plataforma disponibiliza também uma análise estatística onde poderá verificar um total de horas de mão de obra,
        entrada e saída de materiais (os seus custos e as quantidades) e o total do projeto.</h3>
      <img src={OrcAnalise} alt='OrcAnalise' id='imageProjSix' />
      <img src={OrcAnaliseTwo} alt='OrcAnaliseTwo' id='imageProjSix' />

      <h3 id='segundoProj'> Na parte superior da obra, poderá anexar novos documentos ao orçamento e remover os existentes. </h3>
      <img src={OrcDocs} alt='OrcDocs' id='imageProjEleven' />
      <img src={OrcDocsTwo} alt='OrcDocsTwo' id='imageProjTwelve' />

      <h3 id='segundoProj'> Ao clicar na opção “Ir para Obra” será redirecionado para a sua obra que foi criada automaticamente.
        Nela poderá verificar a Ficha do Cliente, onde poderá apenas modificar a descrição. Os restantes dados poderão ser modificados no orçamento.
        Em baixo, poderá verificar dois painéis: O Fluxo de Materiais e o Fluxo de Produção com uma estimativa de custos.
        Eles funcionarão da mesma forma que os fluxos de produção de uma nova obra, onde poderá colocar uma previsão de horas, uma estimativa de custos,
        adicionar um período de trabalho e verificar os colaboradores e
        quantas horas esse colaborador esteve a trabalhar no fluxo. Os fluxos de materiais, serão apresentados a sua quantidade e o seu valor.
      </h3>
      <img src={OrcFluxosTwo} alt='OrcFluxosTwo' id='imageProjTwo' />
      <h3 id='segundoProj'> Poderá verificar a obra criada automaticamente através da listagem de obras da área comercial e alterar os dados através da mesma. </h3>

      {/*                                            COMERCIAL                                                       */}

      <h1 id='tituloProjSegundo'> | ÁREA COMERCIAL</h1>
      <h3 id='segundoProj'> Permite ter um controle comercial de obras que serão necessárias, seleção de documentos respetivos à mesma, a sua respetiva visualização e a
        possibilidade de dar como concluída. Neste exemplo, já existe uma obra exemplo criada, mas iremos abordar a criação de uma nova.
        Poderá filtrar projetos já existentes pelo seu status. A sua obra será criada automaticamente através do registo de um novo orçamento.</h3>
      <img src={Obra} alt='Obra' id='imageProjFifthteen' />


      {/*                                                     AÇÕES                                                       */}

      <h1 id='tituloProjSegundo'> || AÇÕES</h1>
      <h3 id='segundoProj'> Para criação da obra, terá de preencher um pequeno formulário ao clicar na opção "Nova Obra". Ao clicar, preencha os dados do formulário com
        as informações da sua obra e atribua um ID á mesma. Existem ações que a plataforma disponibiliza para conseguir gerir as suas obras da melhor forma.
        A terceira opção, permite á entidade concluir a sua obra.</h3>
      <img src={ObraActions} alt='ObraActions' id='imageProjSeven' />

      <h3 id='segundoProj'> A primeira opção permite selecionar os documentos que pretende que estejam anexados na obra. </h3>
      <img src={Anexos} alt='Anexos' id='imageProjSixteen' />

      <h3 id='segundoProj'> Ao clicar na segunda opção, poderá visualizar de uma forma mais detalhada as informações da sua obra. Existem vários painéis ao visualizarmos a obra.
        No lado esquerdo, temos 2 painéis, A ficha de cliente e a Área Comercial, onde poderá editar as informações do seu cliente, quem é o responsávél pela obra, a data de inicio,
        entre outras opções disponiveis. </h3>
      <img src={FichaCliente} alt='FichaCliente' id='imageProjEight' />
      <img src={AreaComercial} alt='AreaComercial' id='imageProjEight' />

      <h3 id='segundoProj'> O painel do lado direito permite ver os custos previstos da obra, materiais, da mão de obra, os artigos que definiu para esta obra, entre outas informações. </h3>
      <img src={Analise} alt='Analise' id='imageProjNine' />
      <img src={AnaliseTwo} alt='AnaliseTwo' id='imageProjNine' />


      <h3 id='segundoProj'> E um breve resumo do total de horas por fluxo, valor, quantidade e materiais definidos previamente na criação de orçamento. </h3>
      <img src={OrcObra} alt='OrcObra' id='imageProjSix' />

      <h3 id='segundoProj'> A plataforma disponibiliza também uma análise estatística onde poderá verificar um total de horas de mão de obra,
        entrada e saída de materiais (os seus custos e as quantidades) e o total do projeto.</h3>
      <img src={OrcAnalise} alt='OrcAnalise' id='imageProjSix' />
      <img src={OrcAnaliseTwo} alt='OrcAnaliseTwo' id='imageProjSix' />

      <h3 id='segundoProj'> Na parte superior da obra, poderá anexar novos documentos ao orçamento e remover os existentes. </h3>
      <img src={OrcDocs} alt='OrcDocs' id='imageProjEleven' />
      <img src={OrcDocsTwo} alt='OrcDocsTwo' id='imageProjTwelve' />

      <h3 id='segundoProj'> Ao clicar na opção “Ir para Obra” será redirecionado para a sua obra que foi criada automaticamente.
        Nela poderá verificar a Ficha do Cliente, onde poderá apenas modificar a descrição. Os restantes dados poderão ser modificados no orçamento.
        Em baixo, poderá verificar dois painéis: O Fluxo de Materiais e o Fluxo de Produção com uma estimativa de custos.
        Eles funcionarão da mesma forma que os fluxos de produção de uma nova obra, onde poderá colocar uma previsão de horas, uma estimativa de custos,
        adicionar um período de trabalho e verificar os colaboradores e
        quantas horas esse colaborador esteve a trabalhar no fluxo. Os fluxos de materiais, serão apresentados a sua quantidade e o seu valor.
      </h3>
      <img src={OrcFluxosTwo} alt='OrcFluxosTwo' id='imageProjTwo' />
      <h3 id='segundoProj'> Poderá verificar a obra criada automaticamente através da listagem de obras da área comercial e alterar os dados através da mesma. </h3>


      <h3 id='segundoProj'> Note que no topo desta análise, existe uma pequena barra com duas opções no final. Na primeira opção, poderá anexar mais documentos e na segunda opção,
        poderemos ir para a obra, onde serão definidos os fluxos, os artigos, os custos, entre outras informações.</h3>
      <img src={NavActions} alt='NavActions' id='imageProjTen' />

      <h3 id='segundoProj'> Ao clicar na opção "Ir para Obra", podemos visualizar novos painéis. Do lado esquerdo temos uma visualização dos dados do cliente, anteriormente definidos.
        Do lado direito, irá definir os seus fluxos. Para tal, necessita de definir os seus fluxos de produção nas Configurações &rarr; Projetos.
        Após a configuração dos fluxos de produção, poderá definir mais detalhadamente os mesmos. Tomaremos como exemplo o fluxo Materiais.
        Clique no "+" no painel de Fluxo de Produção e selecione o fluxo desejado.</h3>
      <img src={Fluxo} alt='Fluxo' id='imageProjSix' />
      <img src={AddFluxo} alt='AddFluxo' id='imageProjSix' />

      <h3 id='segundoProj'> Após selecionar e adicionar qual fluxo deseja, terá de o editar. </h3>
      <img src={EditFluxo} alt='EditFluxo' id='imageProjSix' />

      <h3 id='segundoProj'> Será aberto um pequeno formulário de configuração do seu fluxo onde poderá colocar informações como a data de inicio, data prevista de entrega e uma
        previsão das horas gastas no mesmo. </h3>
      <img src={ConfigFluxo} alt='ConfigFluxo' id='imageProjSix' />

      <h3 id='segundoProj'> Após a configuração, o fluxo será listado como iniciado. Poderá atribuir este fluxo a vários colaboradores, adicionar mais horas de trabalho e alterar
        as configurações que realizou previamente para este fluxo. </h3>
      <img src={Materiais} alt='Materiais' id='imageProjSix' />

      <h3 id='segundoProj'> Poderá visualizar em forma de gráfico, as percentagens do progresso do seus flows. Ao clicar na segunda opção do painel de Fluxo de Produção, será
        aberto um gráfico. </h3>
      <img src={AnaliseOption} alt='AnaliseOption' id='imageProjSix' />
      <img src={Grafico} alt='Grafico' id='imageProjNine' />

      {/*                                            TÉCNICA                                                       */}

      <h1 id='tituloProjSegundo'> | ÁREA TÉCNICA</h1>
      <h3 id='segundoProj'> Esta aba disponibiliza uma lista de projectos totais da sua empresa, ao qual poderá gerir de modo a
        anexar documentos financeiros, visualizar a obra e dar início à mesma. </h3>
      <img src={Tecnica} alt='Tecnica' id='imageProjFour' />





    </>
  )
}

export default Projects;