import React from 'react';
import ButtonScrollUp from '../../components/ButtonScrollUp';
import Navbar from '../../components/Navbar';
import './index.css';

import FullICred from '../../images/FullICred.png';
import Bancos from '../../images/Bancos.png';
import FlowICred from '../../images/FlowICred.png';
import WorkflowProcessCredit from '../../images/WorkflowProcessCredit.png';
import Intervenientes from '../../images/Intervenientes.png';


function Credito() {
  return (
    <>

      <Navbar />
      <ButtonScrollUp />

      {/*                                              INTERMEDIÁRIO CRÉDITO                                                       */}

      <h1 id='tituloI'> | INTERMEDIÁRIO DE CRÉDITO</h1>
      <h3 id='segundoI'> Este módulo tem como objetivo a agregação dos processos de venda e a possibilidade de visualização de todo o percurso do mesmo até ser resolvido. </h3>
      <img src={FullICred} alt='FullICred' id='imageIOne' />

      <h3 id='segundoI'> É possível verificar quais os bancos que a sua empresa colabora. </h3>
      <img src={Bancos} alt='Bancos' id='imageITwo' />

      <h3 id='segundoI'> O seu Workflow serve para demonstrar quais os processos que estão ativos e os que já foram finalizados dentro desta categoria. </h3>
      <img src={FlowICred} alt='FlowICred' id='imageIThree' />

      <h3 id='segundoI'> No entanto, existe uma pequena diferença relativamente aos Workflows anteriores. A edição do Workflow Process do módulo Intermediário
        de Crédito exibe campos de preenchimento diferentes na aba Financeiro.
        Nele, poderá preencher o valor do financiamento, valor da avaliação e da aquisição, entre outras opções disponíveis. </h3>
      <h3 id='segundoI'><span style={{ color: "red" }}> Nota: Defina os bancos com que a sua empresa colabora e as agências. </span></h3>
      <img src={WorkflowProcessCredit} alt='WorkflowProcessCredit' id='imageIFour' />

      <h3 id='segundoI'> Poderá também, na edição do processo, adicionar entidades que sejam intervenientes neste processo de crédito que ache pertinente a sua participação. </h3>
      <img src={Intervenientes} alt='Intervenientes' id='imageIFive' />






    </>
  )
}

export default Credito;